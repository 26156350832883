/** @jsx jsx */
import { Styled, jsx, Container } from "theme-ui"
import { TypeScale, TypeStyle, ColorPalette } from "@theme-ui/style-guide"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO title="Style Guide" />
    <Container>
      <Styled.h2>Style Guide</Styled.h2>
      <Styled.h3
        sx={{
          color: "secondary",
        }}>
        Type Specimens
      </Styled.h3>
      <TypeStyle
        fontSize="9"
        fontFamily="primary"
        fontWeight="bold"
        lineHeight="heading">
        Ghost
      </TypeStyle>
      <TypeStyle
        fontSize="5"
        fontFamily="primary"
        fontWeight="bold"
        lineHeight="heading">
        Headline
      </TypeStyle>
      <TypeStyle
        fontSize="6"
        fontFamily="primary"
        fontWeight="medium"
        lineHeight="heading">
        Cross Head
      </TypeStyle>
      <TypeStyle
        fontSize="3"
        fontFamily="primary"
        fontWeight="regular"
        lineHeight="body">
        Body
      </TypeStyle>
      <TypeStyle
        fontSize="3"
        fontFamily="primary"
        fontWeight="bold"
        lineHeight="body">
        Button
      </TypeStyle>
      <TypeStyle
        fontSize="2"
        fontFamily="primary"
        fontWeight="bold"
        lineHeight="body">
        {"Menu".toUpperCase()}
      </TypeStyle>
      <TypeStyle
        fontSize="0"
        fontFamily="primary"
        fontWeight="medium"
        lineHeight="body">
        Imprint{" "}
        <span role="img" aria-label="love">
          ❤️
        </span>
      </TypeStyle>
      <Styled.h3
        sx={{
          color: "secondary",
        }}>
        Type Scale
      </Styled.h3>
      <TypeScale />
      <Styled.h3
        sx={{
          color: "secondary",
        }}>
        Color Palette
      </Styled.h3>
      <ColorPalette />
    </Container>
  </Layout>
)
